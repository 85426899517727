import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import ProjectGrid from "../components/projectGrid"
import Wrapper from "../components/modules/wrapper"

// const breakpoints = [576, 768, 992, 1200]
// const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

// const Wrapper = styled.section`
//   margin: 1em 1em 0 1em;
//   ${mq[2]} {
//     margin: 1.5em 1.5em 0 1.5em;
//   }
//   ${mq[3]} {
//     margin: 2em 2em 0 2em;
//   }
// `

const AboutPage = () => (
  <Layout>
    <Wrapper>
      <h1>Projects</h1>
    </Wrapper>
    <ProjectGrid />
  </Layout>
)
export default AboutPage
